import { AbstractManufacturerService } from './abstract-manufacturer-service';
import { DeviceService } from './device-service';
import { SetVolumeLevel } from './device-types';
import { getFeatures, PerformanceFeatureMap } from './performance-features';

export class DesktopService extends AbstractManufacturerService {
  private _map = new PerformanceFeatureMap();

  constructor(private readonly deviceService: DeviceService) {
    super();
  }

  public setVolume(volume: SetVolumeLevel) {
    console.info(volume);
  }

  public getVolume(): number {
    return 0;
  }

  public getAvailableFeatures() {
    return getFeatures(this._map);
  }

  public getHumanReadableDeviceName(): string {
    return `SmartTV App ${this.deviceService.deviceUUID}`;
  }

  public getManufacturerName(): string {
    return `SmartTV App ${this.deviceService.deviceUUID}`;
  }

  public getDeviceInfo(): unknown {
    return undefined;
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public isWeakDevice(): boolean {
    return false;
  }

  public exit() {}
}
