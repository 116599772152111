import AbstractConstantsConfig from '@package/constants/code/abstract-constants-config';

const CONSTANTS = {
  routerNavigationDebounceTimeoutMs: 250,
  appNavigationThrottleTimeoutMs: 100,
  authCheckTvTokenTimeoutMs: 5000,
  resendOtpCodeTimeoutSeconds: 60,
  resendPasswordTimeoutMs: 1000,
  codeResendTimerSeconds: 60,
  numberOfAttemptsParenteralControl: 3,
  debounceTimeoutLegacyMs: 2000,
  debounceTimeoutModernMs: 300,
  expirationTimeQrSeconds: 600,
  updateTimeQrTimeoutMs: 1000,
  loadNextPageOffset: 2,
  mainBlockSize: 5,
  momentsFeedPlaylistCollection: 'collection',
  allFiltersGroup: 'allFilters',
  filtersGroup: 'filters',
  hideControlTimeoutMs: 10_000,
  dropdownPageSize: 5,
  contentPageSize: 5,
  navigationFocusDelay300Ms: 300,
  navigationFocusDelay750Ms: 750,
  navigationFocusDelay200Ms: 200,
  offerCardWidthPx: (1280 / 1920) * 640,
  hidePlayerSeriesMenuTimeoutMs: 113_000,
  checkUserTimeoutMs: 5_000,
  throttleFrameTimeMs: 18,
};

class ConstantsConfigSmartTV extends AbstractConstantsConfig<typeof CONSTANTS> {
  constructor(constantsMap: typeof CONSTANTS) {
    super(constantsMap);
  }
}
const ConstantsConfigInstanceSmartTV = new ConstantsConfigSmartTV(CONSTANTS);

export default ConstantsConfigInstanceSmartTV;
