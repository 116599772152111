import { useCurrentPlayer } from '@package/media-player/src/player';
import { MediaContentType } from '@package/sdk/src/api';
import { Disposable, isEmptyArray, isUndefinedOrNull, UnexpectedComponentStateError } from '@package/sdk/src/core';
import {
  AlertMessageTypes,
  ContentGetters,
  ContentState,
  RouterPage,
  storeToRefs,
  useContentStore,
} from '@SMART/index';
import type { AlertService } from '@SMART/services/notifications/alert-service';
import type { RouterService } from '@SMART/services/router/router-service';

import type {
  RemoteContentStartContentEvent,
  RemoteControlPairedEvent,
  RemoteOffsetChangeEvent,
} from '@/services/remote-control/rc-event';
import type { RemoteControlEvent } from '@/services/remote-control/remote-control-session';
import { useRemoteControlStore } from '@/store/remote-control-store';

export class RemoteControlActionsController extends Disposable {
  constructor(
    private readonly routerService: RouterService,
    private readonly alertService: AlertService,
  ) {
    super();
  }

  private getCurrentMedia = () => {
    const player = useCurrentPlayer();
    const { content } = storeToRefs<ContentState, ContentGetters, NonNullable<unknown>>(useContentStore());

    if (!player || !content.value) {
      console.error(new UnexpectedComponentStateError('player | content'));
      return;
    }

    return {
      player,
      content: content.value,
    };
  };

  public startContent = (event: RemoteControlEvent<RemoteContentStartContentEvent>) => {
    const { playlist, offset } = event.data;

    const remoteControlStore = useRemoteControlStore();

    if (isEmptyArray(playlist)) {
      throw new UnexpectedComponentStateError('playlist');
    }

    remoteControlStore.setRemoteControlPlaylist(playlist);
    const { id: contentId, profile_id } = event.data;

    if (isUndefinedOrNull(contentId)) {
      throw new UnexpectedComponentStateError('contentId');
    }

    const content = playlist.find((content) => content.content_id === contentId);

    if (isUndefinedOrNull(content)) {
      throw new UnexpectedComponentStateError('content');
    }

    const { content_id, parent_content_id, type: contentType } = content;

    remoteControlStore.setCurrentRemoteControlPlaylistContentId(content_id);
    remoteControlStore.setCurrentRemoteControlProfileId(profile_id || '');
    remoteControlStore.setRemoteControlOffset(offset);

    const id = (() => {
      if (contentType === 'movie') {
        return content_id;
      }

      return parent_content_id as string;
    })();

    const type = contentType === 'movie' ? MediaContentType.Movie : MediaContentType.Serial;

    return this.routerService.replace({
      name: RouterPage.MediaCardPlayerPage,
      params: {
        id,
        type,
      },
      query: {
        offset: offset || 0,
        episodeId: contentType === 'series' ? content_id : undefined,
      },
    });
  };

  public devicePaired = (event: RemoteControlEvent<RemoteControlPairedEvent>) => {
    const rcName = event.data.rc.name;

    this.alertService.addAlert({
      type: AlertMessageTypes.Success,
      message: `Пульт ${rcName} подключен`,
      timeoutMs: 5000,
    });
  };

  public stop = () => {
    const media = this.getCurrentMedia();

    if (!media) {
      return;
    }

    const { content, player } = media;

    player.pause({ manual: true });

    return this.routerService.push({
      name: RouterPage.MediaCardPage,
      params: {
        id: content.id,
      },
    });
  };

  public play = () => {
    const media = this.getCurrentMedia();

    if (!media) {
      return;
    }

    const { player } = media;
    player.play({ manual: true });
  };

  public pause = () => {
    const media = this.getCurrentMedia();

    if (!media) {
      return;
    }

    const { player } = media;
    player.pause({ manual: true });
  };

  public seekTo = (event: RemoteControlEvent<RemoteOffsetChangeEvent>) => {
    const media = this.getCurrentMedia();

    if (!media) {
      return;
    }

    const { player } = media;
    player.seekTo({
      offset: event.data.offset,
      coldStart: false,
      manual: true,
    });
  };

  public setQuality = () => {
    const media = this.getCurrentMedia();

    if (!media) {
      return;
    }

    const { player } = media;

    player.setQuality('highest');
  };

  public dispose = () => {
    //
  };
}
