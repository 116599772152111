import { Genre } from '@package/sdk/src/api/content/types/genre';
import { MainPageBlock } from '@package/sdk/src/api/content/types/main-page';
import { Media } from '@package/sdk/src/api/content/types/media';
import { Serial } from '@package/sdk/src/api/content/types/serial';
import { MainPageMapper } from '@package/sdk/src/api/main-page/main-page';
import { ApiMainPage } from '@package/sdk/src/api/main-page/main-page-types';

import { formatDuration, pluralize } from '../../utils/format';
import { Endpoints } from '../endpoints';
import { translate } from '../localization/translate';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export type SubtitleMedia = Partial<Media> & {
  seasonNumber?: number;
  seasonCount?: number;
  genre?: string[];
};

export class PlaylistService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public getLimit(value?: number): string {
    const limit = parseInt(value?.toString());

    if (isNaN(limit)) {
      return '';
    }

    return !isNaN(limit) ? limit + '+' : '';
  }

  public getSubtitle(genres: Genre[], media?: SubtitleMedia): string {
    const seasonNumber = (media as Serial)?.seasons?.length ?? media?.seasonNumber ?? media?.seasonCount ?? 1;
    const rating = parseFloat(media?.imdbRating?.toString() ?? '');
    const duration = parseInt(media?.duration?.toString() ?? '');
    const year = parseInt(media?.year?.toString() ?? '');
    const countries = media?.countries;
    const isSerial = media?.contentType === 'serial';
    const fragments = [
      !isNaN(rating) && rating ? `${rating.toFixed(2)}  IMDB` : null,
      isSerial
        ? `${seasonNumber} ${pluralize(seasonNumber, translate('pluralizedUnit.season') as unknown as string[])}`
        : null,
      !isNaN(duration) && duration >= 60 ? formatDuration(duration, 'hhmm') : null,
      !isNaN(year) ? year : null,
    ];

    if (Array.isArray(media?.genre) && media?.genre?.length) {
      fragments.push(media.genre[0]);
    }

    if (media?.genres?.length) {
      const genreId = media.genres[0].id;

      const genre = genres.find((v) => v.id === genreId);

      fragments.push(genre?.title ? genre.title : null);
    }

    if (Array.isArray(countries) && countries.length > 0) {
      const country = countries[0]?.title;

      fragments.push(country || null);
    }

    return fragments.filter(Boolean).join(', ');
  }

  public getContentSubtitle(media?: SubtitleMedia): string {
    const seasonNumber = (media as Serial)?.seasons?.length ?? media?.seasonNumber ?? media?.seasonCount ?? 1;
    const duration = parseInt(media?.duration?.toString() ?? '');
    const year = parseInt(media?.year?.toString() ?? '');
    const countries = media?.countries;
    const isSerial = media?.contentType === 'serial';
    const fragments: Array<string | number | null> = [];

    if (!isNaN(year)) {
      fragments.push(year);
    }

    if (Array.isArray(countries) && countries.length > 0) {
      const country = countries[0]?.title;
      fragments.push(country || null);
    }

    if (!isNaN(duration) && duration >= 60 && !isSerial) {
      fragments.push(formatDuration(duration, 'hhmm'));
    }

    if (isSerial) {
      fragments.push(`${seasonNumber} ${pluralize(seasonNumber, 'pluralizedUnit.season').toLowerCase()}`);
    }

    return fragments.filter(Boolean).join(' · ');
  }

  public async fetchBlocks(options: { page: number }): Promise<MainPageBlock[]> {
    const { data } = await this.$http.request<ApiMainPage>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.PagesMain,
        params: {
          page: options.page,
          per_page: 5,
          includes: 'primary_content',
        },
      },
      { withToken: true, transformResult: true, skipTokenValidation: true, canAbort: false },
    );

    return MainPageMapper.map(data);
  }
}
