import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { isDesktop } from '@PLAYER/player/base/dom';
import { AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import usePlayerSettings from '@PLAYER/player/modules/video/use-player-settings';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import { useWindowSize } from '@vueuse/core';

export default function useTranslationMediaAnalytics() {
  const analytics = useAnalytics();
  const { realQualitySelectedOption, userSelectedQualityOption, qualityLevels } = usePlayerSettings();

  const { height } = useWindowSize();
  const { isKinom, isLive } = useProjector();

  const getHighestQualityLevel = () => {
    if (!qualityLevels.value.length) {
      return undefined;
    }

    return qualityLevels.value.reduce((acc, curr) => {
      if (curr.height > acc?.height) {
        return curr;
      }

      return acc;
    }, qualityLevels.value[0]);
  };

  const getPlayerPayloadContent = (values: AnalyticValue[]) => {
    const highestQualityLevel = getHighestQualityLevel();

    if (highestQualityLevel) {
      values.push({
        label: AnalyticPayloadLabelName.MaxQuality,
        value: highestQualityLevel.height,
      });
    }

    if (realQualitySelectedOption.value && realQualitySelectedOption.value.rawValue !== 'auto') {
      values.push({
        label: AnalyticPayloadLabelName.Quality,
        value: realQualitySelectedOption.value.rawValue,
      });
    }

    if (userSelectedQualityOption.value) {
      // Если выбрано auto режим, то мы отправляем максимально доступное качество.
      // Исходя из того, что качество проигрывается в максимально высоком качестве.
      // https://viju-ru.slack.com/archives/C042XEUKEE6/p1725274310451759?thread_ts=1725268588.609829&cid=C042XEUKEE6
      const usercapQuality =
        userSelectedQualityOption.value.rawValue === 'auto'
          ? highestQualityLevel?.height
          : userSelectedQualityOption.value.rawValue;

      values.push({
        label: AnalyticPayloadLabelName.UserCap,
        value: usercapQuality,
      });
    }

    if (isDesktop) {
      values.push({
        label: AnalyticPayloadLabelName.ContainerSize,
        value: height.value,
      });
    }

    return values;
  };

  const onPlayerInitialized = () => {
    if (isKinom.value || isLive.value) {
      return;
    }

    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerInitiated,
      values: getPlayerPayloadContent([]),
      eventSenderMode: 'default',
    });
  };

  const onPlayerStarted = () => {
    if (isKinom.value || isLive.value) {
      return;
    }

    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerStarted,
      values: getPlayerPayloadContent([]),
      eventSenderMode: 'default',
    });
  };

  const onPlayerInterrupted = () => {
    if (isKinom.value || isLive.value) {
      return;
    }

    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerInterrupted,
      values: getPlayerPayloadContent([]),
      eventSenderMode: 'stored',
    });
  };

  const onPlayerFatalError = () => {
    if (isKinom.value || isLive.value) {
      return;
    }

    analytics.sendAnalytic({
      name: AnalyticEventNameNew.AutoPlayerFatalError,
      values: getPlayerPayloadContent([]),
      eventSenderMode: 'stored',
    });
  };

  return {
    onPlayerFatalError,
    onPlayerInitialized,
    onPlayerInterrupted,
    onPlayerStarted,
  };
}
