import { translate } from '../services/localization/translate';

export function formatDuration(value: number, format: string, type = 'text'): string {
  let hoursText = '';
  let minutesText = '';
  let minutes = Math.floor(value / 60);

  if (format.includes('hh')) {
    minutes %= 60;
  }

  const hours = Math.floor(value / 3600);
  const seconds = Math.floor((value % 3600) % 60);

  switch (type) {
    case 'text':
      if (hours) {
        hoursText = `${hours} ч`;
      }

      minutesText = `${minutes} мин`;
      break;

    case 'number':
      if (hours) {
        hoursText = `${hours} :`;
      }

      minutesText = `${minutes}`;
      break;

    default:
      break;
  }

  return `
    ${format.includes('hh') ? hoursText : ''}
    ${format.includes('mm') && minutes > 0 ? minutesText : ''}
    ${format.includes('ss') && seconds > 0 ? hoursText : ''}
    `.trim();
}

export function pluralize(count: number, key: string): string {
  const lastDigit = count % 10;

  if (lastDigit === 1) {
    return translate(`${key}.0`);
  }

  if (lastDigit > 1 && lastDigit < 5) {
    return translate(`${key}.1`);
  }

  return translate(`${key}.2`);
}

export function textOverflow(text: string, textLength: number): string {
  return text?.split(' ')?.slice(0, textLength).join(' ') ?? '';
}
