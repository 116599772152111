<template>
  <div :class="$style.console">
    <div :class="$style.header">
      <span :class="$style.title">{{ $t('debug.terminal') }} - {{ $t('debug.terminal.settings') }}</span>
      <app-button
        :on-click="onShowSettings"
        variation="button-secondary"
        :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerDebug)"
        :text="$t('common.close')"
      />
    </div>
    <section :class="$style.body">
      <div :class="$style.group">
        <p :class="$style.title">
          {{ $t('debug.terminal.chromecast') }}
        </p>
        <div :class="$style.items">
          <app-checkbox v-model:input="isCloseChromecastOnPlayerDispose" :text="$t('debug.settings.chromecast')" />
          <app-button
            :disabled="!isPlayingOnRemoteDevice"
            :on-click="onCloseChromecastClick"
            :data-navigatable="getNavigationAttribute(SmartTvPlayerNavigatableGroupName.PlayerDebug)"
            variation="link-alert"
            :text="$t('debug.settings.disableChromecast')"
          />
        </div>
      </div>
      <div :class="$style.group">
        <p :class="$style.title">
          {{ $t('debug.terminal.playback') }}
        </p>
        <div :class="$style.items">
          <app-checkbox
            v-model:input="isLastQualityLevelRemembered"
            :text="$t('debug.settings.rememberLastQualityLevel')"
          />
        </div>
      </div>
    </section>
    <footer :class="$style.footer">
      <p>
        {{ $t('debug.settings.information') }}
      </p>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { KeyCode } from '@package/sdk/src/core';
import { LocalStorageKey, useLocalStorage } from '@PLAYER/player/base/local-storage';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import AppCheckbox from '@PLAYER/player/components/ui/AppCheckbox.vue';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import {
  SmartTvPlayerNavigatableGroupName,
  useNavigatableMap,
} from '@PLAYER/player/versions/smart/modules/smart-navigation/use-navigatable-map';
import { storeToRefs } from 'pinia';
import { onUnmounted, ref, watch } from 'vue';

const emit = defineEmits<{
  (event: 'on-show-settings'): void;
}>();

const keyboard = useKeyboardHandler();
const localStorage = useLocalStorage();
const { getNavigationAttribute } = useNavigatableMap();

const videoInteractions = useVideoInteractions();
const { isPlayingOnRemoteDevice } = storeToRefs(useVideoUIStore());

const onShowSettings = () => {
  emit('on-show-settings');
};

const onCloseChromecastClick = () => {
  videoInteractions.stopRemotePlay();
};

const escapeHandler = keyboard.on(KeyCode.Escape, onShowSettings);

const isCloseChromecastOnPlayerDispose = ref<boolean>(
  localStorage.getValue(LocalStorageKey.DisposeChromecastOnUnload, false).value as boolean,
);
watch(isCloseChromecastOnPlayerDispose, (value) => {
  localStorage.setValue(LocalStorageKey.DisposeChromecastOnUnload, {
    value,
  });
});

const isLastQualityLevelRemembered = ref<boolean>(
  localStorage.getValue(LocalStorageKey.RememberLastQualityLevel, false).value as boolean,
);
watch(isLastQualityLevelRemembered, (value) => {
  localStorage.setValue(LocalStorageKey.RememberLastQualityLevel, {
    value,
  });
});

onUnmounted(() => {
  escapeHandler.dispose();
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;

.console {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: var(--g-spacing-20);
}

.title {
  @include webFonts.WebLabel-1();
  margin-right: auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--g-spacing-16);
}

@supports (position: sticky) {
  .header {
    position: sticky;
    top: -20px;
  }
}

.message {
  background-color: inherit !important;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--g-spacing-16);
}

.group {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--g-spacing-8);
}

.items {
  display: grid;
  grid-template-columns: max-content;
  align-items: flex-start;
  gap: var(--g-spacing-8);
}

.footer {
  border-top: 1px solid var(--color-notheme-icon-primary);
  padding-top: var(--g-spacing-4);
  margin-top: auto;

  & > p {
    @include webFonts.WebBody-3();
  }
}
</style>
