import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import {
  AxiosBasicCredentials,
  AxiosError,
  AxiosHeaders,
  AxiosProxyConfig,
  AxiosRequestConfig,
  AxiosResponse,
  ParamsSerializerOptions,
  RawAxiosRequestHeaders,
} from 'axios';

import { ApiError } from './api-error';
import type { LoginResponse } from './auth/auth-types';
import type { DeviceService } from './device/device-service';
import { Endpoints } from './endpoints';
import type { EnvironmentService } from './environment/environment-service';
import { globalSettings } from './global-settings';
import { AlertMessageTypes, AlertService } from './notifications/alert-service';
import type { IStorageService } from './storage/storage-service';
import { StorageKeys } from './storage/storage-types';

export enum HTTPRequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

interface RequestOptions<T, P> {
  proxy?: AxiosProxyConfig;
  baseURL?: string;
  url: string;
  data?: T;
  method?: 'GET' | 'POST' | 'DELETE' | 'OPTIONS' | 'PUT' | 'PATCH' | 'HEAD';
  params?: P;
  cache?: boolean;
  headers?: RawAxiosRequestHeaders | AxiosHeaders;
  paramsSerializer?: ParamsSerializerOptions;
  auth?: AxiosBasicCredentials;
}

interface RequestConfig {
  transformResult?: boolean;
  withToken?: boolean;
  withSignature?: boolean;
  canAbort?: boolean;
  skipTokenValidation?: boolean;
}

interface SignatureParams<T> {
  body: T;
  path: string;
  login?: string;
  method?: string;
  contentType?: string;
}

export class RequestService {
  private readonly errorInterceptors = new Set<(error: Error) => void>();

  constructor(
    private readonly $device: DeviceService,
    private readonly $storage: IStorageService,
    private readonly $environment: EnvironmentService,
    private readonly alertService: AlertService,
  ) {
    this.registerListeners();
  }

  private abortControllers: AbortController[] = [];
  public refreshRequestPromise: Promise<AxiosResponse<LoginResponse>> | undefined;

  private get refreshToken() {
    return this.$storage.getItem<string>(StorageKeys.RefreshToken);
  }

  private get accessToken() {
    return this.$storage.getItem<string>(StorageKeys.AccessToken);
  }

  private getApiBaseURL(): string {
    const isRelease = this.$environment.getVariable('isRelease');

    if (isRelease) {
      return this.$environment.getVariable('apiBaseURL');
    }

    const isDebugProdApiEnabled = this.$storage.getItem(StorageKeys.isProdApiEnabledDebug, false);

    if (isDebugProdApiEnabled) {
      return this.$environment.getVariable('debugApiBaseProdURL');
    }

    return this.$environment.getVariable('apiBaseURL');
  }

  public abort(message = 'Cancelled by user') {
    this.abortControllers.forEach((controller) => controller.abort(message));
    this.abortControllers = [];
  }

  public async request<R, T = object, P = object>(
    options: RequestOptions<T, P>,
    config: RequestConfig = {},
  ): Promise<AxiosResponse<R>> {
    const { transformResult = false, withSignature = false, withToken = false, canAbort = true } = config;

    const controller = new AbortController();

    if (canAbort) {
      this.abortControllers.push(controller);
    }

    const Authorization = `Bearer ${this.accessToken}`;

    const headers = {
      VisitorId: this.$device.getVisitorId(),
      'Accept-Language': 'ru-RU',
      ...options.headers,
      ...(withToken && { Authorization }),
      ...(withSignature && this.getSignature({ body: options.data, path: options.url })),
    };

    const normalizedOptions = {
      baseURL: this.getApiBaseURL(),
      ...options,
      headers,
      signal: controller.signal,
    } as AxiosRequestConfig;

    if (!normalizedOptions.method) {
      throw new UnexpectedComponentStateError('opts.method');
    }

    if (!transformResult) {
      return globalSettings.axios.request(normalizedOptions);
    }

    const doRequest = async () => {
      try {
        this.refreshRequestPromise = undefined;
        return await globalSettings.axios.request({ ...normalizedOptions });
      } catch (error) {
        if (error instanceof Error) {
          const axiosError = error as AxiosError;

          if (globalSettings.axios.isCancel(error)) {
            return Promise.reject(error);
          }

          if (!withToken) {
            throw new ApiError(axiosError as AxiosError);
          }

          if (axiosError.response?.status === HTTPStatusCode.Unauthorized) {
            await this.updateTokens();

            const { headers } = normalizedOptions;

            return await globalSettings.axios.request({
              ...normalizedOptions,
              headers: {
                ...headers,
                ...(withToken && { Authorization: `Bearer ${this.accessToken}` }),
              } as RawAxiosRequestHeaders,
              signal: controller.signal,
            });
          }

          throw new ApiError(axiosError);
        }
      }
    };

    try {
      return await doRequest();
    } catch (error) {
      // eslint-disable-next-line n/no-callback-literal
      this.errorInterceptors.forEach((callback) => callback(error as AxiosError));

      throw error;
    }
  }

  public getSignature<T>({
    body,
    path,
    login = 'tv',
    method = 'POST',
    contentType = 'application/json',
  }: SignatureParams<T>) {
    const API_SECRET_KEY = this.$environment.getVariable<string>('apiSecretKey');
    const contentMD5 = globalSettings.cryptoJs.enc.Base64.stringify(globalSettings.cryptoJs.MD5(JSON.stringify(body)));
    const date = new Date().toUTCString();

    const message = [method, contentType, contentMD5, path, date].join(',');
    const encrypted = globalSettings.cryptoJs
      .HmacSHA1(message, API_SECRET_KEY)
      .toString(globalSettings.cryptoJs.enc.Base64);
    const authorization = `APIAuth ${login}:${encrypted}`;

    return {
      authorization,
      'Http-Date': date,
      'Content-MD5': contentMD5,
      'Content-Type': contentType,
    };
  }

  public async updateTokens(token?: string): Promise<LoginResponse | undefined> {
    try {
      if (!(token ?? this.refreshToken)) {
        return undefined;
      }

      const body = {
        refresh_token: token ?? this.refreshToken,
      };

      if (this.refreshRequestPromise) {
        const { data } = await this.refreshRequestPromise;

        return data;
      }

      this.refreshRequestPromise = this.request(
        {
          method: HTTPRequestMethod.Post,
          url: Endpoints.AuthRefreshToken,
          data: body,
        },
        { transformResult: true, withSignature: true },
      );

      const { data } = await this.refreshRequestPromise;

      this.$storage.setItem(StorageKeys.AccessToken, data.auth.token);
      this.$storage.setItem(StorageKeys.RefreshToken, data.auth.refresh_token);

      return data;
    } catch (error) {
      this.$storage.setItem(StorageKeys.User, '');
      this.$storage.setItem(StorageKeys.Profile, '');
      this.$storage.setItem(StorageKeys.AccessToken, '');
      this.$storage.setItem(StorageKeys.RefreshToken, '');
    }

    return undefined;
  }

  private registerListeners() {
    const onError = (error: Error) => {
      this.alertService.addAlert({
        type: AlertMessageTypes.Error,
        message: error.message,
        timeoutMs: 3000,
        hideIcon: false,
      });
    };

    // const isReleaseMode = this.$environment.getVariable<boolean>('isRelease');
    const isDevMode = this.$environment.getVariable<boolean>('isDev');

    if (isDevMode) {
      this.errorInterceptors.add(onError);
    }
  }
}
