// @ts-nocheck

import { isUndefined, isUndefinedOrNull, toDisposable } from '@package/sdk/src/core';
import { QualityLevel } from '@PLAYER/player/modules/interfaces/hls';
import logger from '@PLAYER/player/modules/logger/default-logger';
import {
  MediaSourceEventErrorType,
  MediaSourceTechEvent,
  MediaSourceTechEventError,
  MediaSourceTechEventFragmentChanged,
  MediaSourceTechEventFragmentLoaded,
  MediaSourceTechEventManifestParsed,
  MediaSourceTechEventQualityLevelSwitched,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { HlsTechConstructorOptions } from '@PLAYER/player/tech/hls/hls-tech-interfaces';
import MediaSourceTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';
import loadHlsJsModule from '@PLAYER/player/tech-loaders/hls-js-loader';
import type { ErrorData, HlsConfig, LevelSwitchedData, ManifestParsedData } from 'hls.js';
import type Hls from 'hls.js';

const defaultHlsConfig: Partial<HlsConfig> = {
  startLevel: 0,
  maxBufferLength: 30,
  enableWorker: true,
  backBufferLength: 15,
};

const hlsErrorMapToMediaSource: Record<string, MediaSourceEventErrorType> = {
  bufferStalledError: 'buffer-error',
  manifestLoadError: 'manifest-network-error',
  manifestLoadTimeout: 'manifest-network-error',
  manifestParsingError: 'manifest-parsing-error',
};

export default class HlsMediaTech extends MediaSourceTech<HTMLVideoElement> {
  private tech: Hls;
  private readonly hlsConfig: Partial<HlsConfig>;

  constructor(options: HlsTechConstructorOptions) {
    super();

    this.hlsConfig = Object.assign({}, defaultHlsConfig, options.hlsConfig);
  }

  public get hlsInstance() {
    return this.tech;
  }

  public get bandwidth(): number {
    if (!this.tech) {
      return 0;
    }

    return Number((this.tech.bandwidthEstimate / 1024).toFixed(2));
  }

  public get videoCodec(): string {
    if (!this.tech) {
      return '';
    }

    return this.tech.levels[this.tech.currentLevel]?.videoCodec;
  }

  public get audioCodec(): string {
    if (!this.tech) {
      return '';
    }

    return this.tech.levels[this.tech.currentLevel]?.audioCodec;
  }

  public get currentQualityLevelHeight(): number {
    if (!this.tech) {
      return 0;
    }

    return this.tech.levels[this.tech.currentLevel]?.height;
  }

  public get latency(): number {
    if (!this.tech) {
      return 0;
    }

    return this.tech.latency;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    if (!this.tech) {
      return { length: 0, start: 0 };
    }

    const { mainForwardBufferInfo } = this.tech;

    return {
      length: mainForwardBufferInfo?.len || 0,
      start: mainForwardBufferInfo?.start || 0,
    };
  }

  public async init() {
    const HlsModule = await loadHlsJsModule();

    this.tech = new HlsModule(this.hlsConfig);
    logger.info('HlsMediaTech - init', this.hlsConfig);

    this.disposableStore.add(
      toDisposable(() => {
        this.tech.stopLoad();
        this.tech.detachMedia();
        this.tech.destroy();
      }),
    );

    this.registerListeners();
  }

  public stopLoad() {
    logger.info('HlsMediaTech - stopLoad');

    this.tech.stopLoad();
  }

  public startLoad(offset = 0) {
    logger.info('HlsMediaTech - startLoad');

    this.tech.startLoad(offset);
  }

  public loadSource(options: MediaSourceLoadOptions) {
    const { src, offset } = options;

    this.tech.startLoad(offset);
    this.tech.loadSource(src);
  }

  public recoverMediaError() {
    logger.info('HlsMediaTech - recoverMediaError');

    this.tech.recoverMediaError();
  }

  public attachMedia(element: HTMLVideoElement) {
    super.attachMedia(element);

    logger.info('HlsMediaTech - attachMedia');

    this.tech.attachMedia(element);
  }

  public detachMedia() {
    super.detachMedia();

    logger.info('HlsMediaTech - detachMedia');

    this.tech.detachMedia();
  }

  public setNextLevel(level: number) {
    this.tech.nextLevel = level;
  }

  public getNextLevel() {
    return this.tech.nextLevel;
  }

  protected registerListeners(): void {
    const onFragLoaded = () => {
      const firstLevel = this.tech.levels[0];
      const levelTotalDuration = firstLevel?.details?.totalduration;

      if (isUndefined(levelTotalDuration)) {
        return;
      }

      const startFragmentTime = firstLevel.details?.fragments[0].start as number;
      const endFragmentTime = firstLevel.details?.fragments[0].end as number;

      const event = new MediaSourceTechEvent<MediaSourceTechEventFragmentLoaded>({
        tech: 'hls',
        originalEvent: firstLevel,
        data: { startFragmentTime, levelTotalDuration, endFragmentTime },
      });

      this.emitter.emit('fragment-loaded', event);
    };

    const onFragChanged = () => {
      const currentLevel = this.tech.levels[this.tech.currentLevel];
      const currentFragments = currentLevel.details?.fragments;

      if (isUndefinedOrNull(currentFragments)) {
        return;
      }

      const currentFragStartTime = currentFragments[0]?.start;

      if (isUndefinedOrNull(currentFragStartTime)) {
        return;
      }

      const currentTime = this.videoEl?.currentTime - currentFragStartTime;
      const levelTotalDuration = currentLevel.details?.totalduration;

      this.emitter.emit(
        'fragment-changed',
        new MediaSourceTechEvent<MediaSourceTechEventFragmentChanged>({
          tech: 'hls',
          // since its new event, there is no need in original event
          originalEvent: undefined,
          data: {
            currentTime,
            startFragmentTime: currentFragStartTime,
            levelTotalDuration,
          },
        }),
      );
    };

    const onFragBuffered = () => {
      this.emitter.emit('fragment-buffered');
    };

    const onError = (_: string, error: ErrorData) => {
      const hlsDetailsError = error.details;

      const errorType = hlsErrorMapToMediaSource[hlsDetailsError] || '';

      const isFatalError = false;

      this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'hls',
          originalEvent: error,
          data: {
            errorType,
            fatal: isFatalError,
          },
        }),
      );
    };

    const onManifestParsed = (_: string, manifest: ManifestParsedData) => {
      const levels: QualityLevel[] = manifest.levels
        .map((level, index) => ({
          width: level.width,
          height: level.height,
          id: index,
        }))
        .filter((level) => level.width > 0);

      const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
        tech: 'hls',
        originalEvent: manifest,
        data: {
          qualityLevels: levels,
        },
      });

      this.emitter.emit('manifest-parsed', mediaEvent);
    };

    const onQualityLevelSwitched = (_: string, data: LevelSwitchedData) => {
      const level = data.level;

      const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventQualityLevelSwitched>({
        tech: 'hls',
        originalEvent: data,
        data: {
          level,
        },
      });

      this.emitter.emit('quality-level-switched', mediaEvent);
    };

    const onSubtitleTrackLoaded = () => {
      this.emitter.emit('subtitle-track-loaded');
    };

    const onSubtitleTrackUpdated = () => {
      this.emitter.emit('subtitle-track-updated');
    };

    this.tech.on('hlsSubtitleTrackLoaded', onSubtitleTrackLoaded);
    this.tech.on('hlsSubtitleTracksUpdated', onSubtitleTrackUpdated);

    this.tech.on('hlsError', onError);
    this.tech.on('hlsFragLoaded', onFragLoaded);
    this.tech.on('hlsFragChanged', onFragChanged);
    this.tech.on('hlsFragBuffered', onFragBuffered);

    this.tech.on('hlsManifestParsed', onManifestParsed);
    this.tech.on('hlsLevelSwitched', onQualityLevelSwitched);
  }

  public dispose() {
    logger.info('HlsMediaTech - dispose');

    super.dispose();
  }
}
