import { UnexpectedPropertyConditionError } from '@package/media-player/src/player/errors/unexpected-property-condition-error';
import type { MediaPlayerPlugin } from '@package/media-player/src/player/modules/plugin/media-player-plugin';
import { Disposable, EventEmitter, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type Hls from 'hls.js';

import type { MediaAnalyzerEvent, MediaAnalyzerEventMap } from '../tech/abstract-media-analyzer';
import { HlsAnalyzer } from '../tech/hls-analyzer';
import { Html5Analyzer } from '../tech/html5-analyzer';

// @ts-expect-error
export class MediaPlayerAnalyzerInstance extends Disposable implements MediaPlayerPlugin {
  private readonly analyzer: Html5Analyzer | HlsAnalyzer;
  public readonly emitter = new EventEmitter<MediaAnalyzerEventMap>();

  constructor(type: 'native' | 'hls', tech: HTMLVideoElement | Hls) {
    super();

    if (!tech) {
      throw new UnexpectedComponentStateError('tech');
    }

    if (type === 'native') {
      const isVideoElement = tech instanceof HTMLVideoElement;

      if (!isVideoElement) {
        throw new UnexpectedComponentStateError('tech');
      }

      this.analyzer = new Html5Analyzer(tech);
    } else if (type === 'hls') {
      this.analyzer = new HlsAnalyzer(tech as Hls);
    } else {
      throw new UnexpectedPropertyConditionError('type', type, 'native | hls');
    }

    this.registerListeners();
  }

  private registerListeners() {
    const onPlaybackInterrupted = (event: MediaAnalyzerEvent<'playback-interrupted'>) => {
      this.emitter.emit('playback-interrupted', event);
    };

    const onPlaybackFailed = (event: MediaAnalyzerEvent<'playback-failed'>) => {
      this.emitter.emit('playback-failed', event);
    };

    this.analyzer.emitter.on('playback-interrupted', onPlaybackInterrupted);
    this.analyzer.emitter.on('playback-failed', onPlaybackFailed);
  }

  public dispose() {
    this.analyzer.dispose();
  }
}
