/**
 * @vitest-environment jsdom
 */

/**
 @ @author Teodor_Dre <swen295@gmail.com>
 *
 * @description return first element
 *
 * @param {T[]} array
 * @return {T | undefined}
 */
export function getFirstElement<T>(array: T[]): T | undefined {
  if (!array) {
    return undefined;
  }

  if (array.length > 0) {
    return array[0];
  }

  return undefined;
}

/**
 * @author Teodor_Dre <swen295@gmail.com>
 *
 * @description
 *  Return random element from array
 *
 * @returns {boolean}
 */
export function isEmptyArray(array: unknown): boolean {
  return (array as unknown[])?.length === 0;
}

/**
 * @author Teodor_Dre <swen295@gmail.com>
 *
 * @description
 *  Return last element from array
 *
 * @param {T[]} array - array with elements;
 *
 * @returns T[]
 */
export function getLastElement<T>(array: ReadonlyArray<T | undefined | null>): T | undefined | null {
  const length = array?.length;

  if (!length) {
    return;
  }

  return array[array.length - 1];
}

export function indexOutOfRange(index: number): boolean {
  return index === -1;
}

export function coalesce<T>(array: ReadonlyArray<T | undefined | null>): T[] {
  return array.filter((e) => Boolean(e)) as T[];
}

// TODO: Inline Source testing не работает в Nuxt, нужно разобраться (тесты пока оставлю)
// if (import.meta.vitest) {
//   const { it, expect } = import.meta.vitest;
//
//   const array = [1, 2, 3];
//   const coalesceArray = [undefined, null, {}, 3, 0, 1];
//   const empty: unknown[] = [];
//
//   it('coalesce', () => {
//     expect(coalesce(coalesceArray)).toHaveLength(3);
//   });
//
//   it('getFirstElement', () => {
//     expect(getFirstElement(array)).toBe(1);
//   });
//
//   it('getLastElement', () => {
//     expect(getLastElement(array)).toBe(3);
//   });
//
//   it('isEmptyArray -> negative', () => {
//     expect(isEmptyArray(array)).toBe(false);
//   });
//
//   it('isEmptyArray -> positive', () => {
//     expect(isEmptyArray(empty)).toBe(true);
//   });
// }
