import ConstantsConfigInstanceSmartTV from '@package/constants/code/smart-tv-constants-instance';
import { isFunction, isUndefinedOrNull } from '@package/sdk/src/core';
import { TvKeyCode } from '@package/sdk/src/core/keyboard/tv-keys';
import { Disposable } from '@package/sdk/src/core/lifecycle/disposable';
import { debounce } from '@package/sdk/src/core/std/async';
import type {
  NavigationFailure,
  NavigationGuardWithThis,
  RouteLocationNormalized,
  RouteLocationRaw,
  Router,
} from 'vue-router';

import { AppKeyboardEvent, keyboardEventHandler } from '../../navigation/keyboard-event-handler';
import { globalSettings } from '../global-settings';
import logger from '../logger/logger';
import type { AppNavigationService } from '../navigation/app-navigation-service';
import type { RequestService } from '../request-service';

interface RouterForwardNavigateOptions {
  route: RouteLocationRaw;
  replace: boolean;
}

declare global {
  interface Window {
    $exports?: {
      showAppExitModal: () => Promise<boolean>;
    };
  }
}

export class RouterService extends Disposable {
  public _router: any;

  constructor(
    private readonly requestService: RequestService,
    private readonly appNavigationService: AppNavigationService,
  ) {
    super();
  }

  private _lastVisitedRoute: RouteLocationNormalized | undefined;

  public get fullPath(): string {
    const isVue3 = !isUndefinedOrNull(globalSettings.router.createRouter);

    if (isVue3) {
      const router = this._router as Router;

      return router.currentRoute.value.fullPath;
    }

    return this._router.currentRoute.fullPath;
  }

  public get route() {
    const isVue3 = !isUndefinedOrNull(globalSettings.router.createRouter);

    if (isVue3) {
      const router = this._router as Router;

      return router.currentRoute.value;
    }

    return this._router.currentRoute;
  }

  public initialize() {
    const isVue3 = !isUndefinedOrNull(globalSettings.router.createRouter);

    if (isVue3) {
      this._router = globalSettings.router.createRouter({
        history: globalSettings.router.createWebHashHistory(),
        routes: globalSettings.routes,
      });
    } else {
      const VueRouter = globalSettings.router;
      this._router = new VueRouter({
        routes: globalSettings.routes,
      });
    }

    this.addBeforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next) =>
      this.appNavigationService.checkCanGoBack(to, from, next),
    );

    keyboardEventHandler.on(TvKeyCode.RETURN, this.onBackspace.bind(this));

    // set default values before route change
    this.addBeforeEach((_to: RouteLocationNormalized, _from: RouteLocationNormalized, next) => {
      this.appNavigationService.activeNavigationItem.value = undefined;
      this.appNavigationService.activeNavigationGroup.value = '';
      this.appNavigationService.setOnPressBackCallback();
      this.appNavigationService.setOnNavigate();
      this.appNavigationService.setCustomScroll();

      if (next) {
        next();
      }
    });
  }

  private async doRouterNavigate(navigateFn: () => Promise<NavigationFailure | undefined | void>) {
    const startTime = performance.now();

    this._lastVisitedRoute = this.route;

    try {
      const navigationFailure = await navigateFn();

      if (navigationFailure) {
        logger.error('RouterService#doRouterNavigate', `Navigation failure ${navigationFailure}`);
      }

      const endTime = performance.now();

      console.info('RouterService#doRouterNavigate', `Nav timeMs: ${endTime - startTime}`);
    } catch (error) {
      logger.error('RouterService#doRouterNavigate', `Navigation failure ${error}`);
    }
  }

  public get lastVisitedRoute() {
    return this._lastVisitedRoute;
  }

  private doBackNavigate() {
    return this.doRouterNavigate(() => Promise.resolve(this._router.back()));
  }

  private doPushNavigate(route: RouteLocationRaw) {
    return this.doForwardNavigate({ replace: false, route });
  }

  private doReplaceNavigate(route: RouteLocationRaw) {
    return this.doForwardNavigate({ replace: true, route });
  }

  private doForwardNavigate(options: RouterForwardNavigateOptions) {
    const { replace, route } = options;

    return this.doRouterNavigate(() => {
      if (replace) {
        return this._router.replace(route);
      }

      return this._router.push(route);
    });
  }

  public back = debounce(
    this.doBackNavigate.bind(this),
    ConstantsConfigInstanceSmartTV.getProperty('routerNavigationDebounceTimeoutMs'),
  );

  public push = debounce(
    this.doPushNavigate.bind(this),
    ConstantsConfigInstanceSmartTV.getProperty('routerNavigationDebounceTimeoutMs'),
  );

  public replace = debounce(
    this.doReplaceNavigate.bind(this),
    ConstantsConfigInstanceSmartTV.getProperty('routerNavigationDebounceTimeoutMs'),
  );

  public get router() {
    return this._router;
  }

  public backspaceHandlerInProgress: boolean = false;

  public async onBackspace(event: AppKeyboardEvent) {
    event.preventDefault();

    if (this.backspaceHandlerInProgress) {
      return;
    }

    const isEmptyRouteHistory = globalSettings.vueVersion === 'vue3' ? !this._router.options.history.state.back : false;

    if (isEmptyRouteHistory) {
      if (window.$exports && isFunction(window.$exports.showAppExitModal)) {
        this.backspaceHandlerInProgress = true;
        const res = await window.$exports.showAppExitModal();

        this.backspaceHandlerInProgress = false;
        if (!res) {
          return;
        }
      }
    }

    if (this.appNavigationService.onPressBackFn) {
      return this.appNavigationService.onPressBackFn();
    }

    return this.back();
  }

  public addAfterEach(guard: NavigationGuardWithThis<undefined>): VoidFunction {
    return this._router?.afterEach(guard);
  }

  public addBeforeEach(guard: NavigationGuardWithThis<undefined>): VoidFunction {
    return this._router?.beforeEach(guard);
  }

  public addBeforeResolve(guard: NavigationGuardWithThis<undefined>): VoidFunction {
    return this._router?.beforeResolve(guard);
  }

  public resolve(to: RouteLocationRaw, currentLocation?: RouteLocationNormalized) {
    return this._router?.resolve(to, currentLocation);
  }
}
