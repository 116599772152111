import { MainPageBlock, Media, ProfileType } from '@package/sdk/src/api';

import { defineStore, getStoreProperty, VuexStoreNamespace } from './utils';

export type MainPageSelectedBeltItem = {
  group: string;
  index: number;
  id: string;
};

export interface MainPageState {
  _selectedBeltItem: MainPageSelectedBeltItem;
  _blocks: MainPageBlock[];
  _watchingItemsV2: Media[];
  _profileType: ProfileType;
}

export interface MainPageGetters {
  selectedBeltItem: MainPageSelectedBeltItem;
  blocks: MainPageBlock[];
  watchingItemsV2: Media[];
  profileType: ProfileType;
}

export interface MainPageActionsWithoutStoreArgument {
  setBlocks(blocks: MainPageBlock[]): void;

  setWatchingItemsV2(items: Media[]): void;

  reset(): void;

  updateSelectedBeltItem(item: MainPageSelectedBeltItem): void;

  setProfileType(type: ProfileType): void;
}

export const useMainPageStore = defineStore<
  MainPageState,
  MainPageGetters,
  MainPageActionsWithoutStoreArgument,
  VuexStoreNamespace.MainPage
>(VuexStoreNamespace.MainPage, {
  state: (): MainPageState => ({
    _selectedBeltItem: { group: 'playlist-1', index: 0, id: '' },
    _blocks: [],
    _watchingItemsV2: [],
    _profileType: ProfileType.COMMON,
  }),
  getters: {
    selectedBeltItem: (state) => state._selectedBeltItem,
    blocks: (state) => state._blocks,
    watchingItemsV2: (state) => state._watchingItemsV2,
    profileType: (state) => state._profileType,
  },
  actions: {
    setBlocks(store, blocks: MainPageBlock[]) {
      store.commit(getStoreProperty(VuexStoreNamespace.MainPage, '$patch'), { _blocks: blocks });
    },
    setWatchingItemsV2(store, items: Media[]) {
      store.commit(getStoreProperty(VuexStoreNamespace.MainPage, '$patch'), { _watchingItemsV2: items });
    },
    setProfileType(store, profileType: ProfileType) {
      store.commit(getStoreProperty(VuexStoreNamespace.MainPage, '$patch'), { _profileType: profileType });
    },
    reset(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.MainPage, '$patch'), {
        _blocks: [],
        _watchingItemsV2: [],
        _selectedBeltItem: { group: 'playlist-1', index: 0 },
      });
    },
    updateSelectedBeltItem(store, item: MainPageSelectedBeltItem): void {
      store.commit(getStoreProperty(VuexStoreNamespace.MainPage, '$patch'), { _selectedBeltItem: item });
    },
  },
});
