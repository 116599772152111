import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { PlayerInstanceConstructorOptions } from '@PLAYER/player/modules/instance/interfaces';
import { validateConfig } from '@PLAYER/player/versions/web/modules/config/validate-config';
import { getCurrentInstance, inject } from 'vue';

const injectKey = 'app.config';

export default function useSafeAppConfig() {
  const app = getCurrentInstance();

  const config = inject<PlayerInstanceConstructorOptions>(injectKey, app?.appContext.provides[injectKey]);

  if (!config) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return validateConfig(config);
}
