<template></template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { MediaErrorStatusCode, NativeMediaError } from '@package/sdk/src/core/media/media-error';
import { HlsRuntimeError } from '@PLAYER/player/errors/hls-runtime-error';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useLogger from '@PLAYER/player/modules/global/use-logger';
import isHlsHTTPError from '@PLAYER/player/modules/hls/is-hls-http-error';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';
import { MediaSourceTechEvent, MediaSourceTechEventError } from '@PLAYER/player/tech/events/media-source-tech-event';
import type { ErrorData } from 'hls.js';
import { ref, watch } from 'vue';

const logger = useLogger();
const externalEventBus = useSafeExternalEventBus();
const getMediaSourceTech = useSafeGetMediaSourceTech();

const unrecoverableErrorData = ref<ErrorData | Error>();

const handleNativeMediaError = (error: MediaError) => {
  const mediaSourceTech = getMediaSourceTech();

  if (error instanceof MediaError) {
    const mediaError = new NativeMediaError(error);

    switch (mediaError.code) {
      case MediaErrorStatusCode.MEDIA_ERR_SRC_NOT_SUPPORTED:
        unrecoverableErrorData.value = mediaError;
        break;
      case MediaErrorStatusCode.MEDIA_ERR_ABORTED:
        break;
      case MediaErrorStatusCode.MEDIA_ERR_NETWORK:
      case MediaErrorStatusCode.MEDIA_ERR_DECODE:
        mediaSourceTech.recoverMediaError();
        break;
    }

    externalEventBus.emit('error', mediaError);
  }
};

const handleHlsError = (data: ErrorData) => {
  const { type, fatal, details } = data;

  if (isHlsHTTPError(data)) {
    externalEventBus.emit('error', new HlsRuntimeError(data));
  } else {
    try {
      logger.error('HlsError', JSON.stringify(data));
    } catch (error) {
      //
    }
  }

  if (!fatal) {
    return;
  }

  if (type === 'networkError' && details === 'manifestParsingError') {
    unrecoverableErrorData.value = data;
    return;
  }

  const mediaSourceTech = getMediaSourceTech();

  switch (type) {
    case 'networkError':
      return mediaSourceTech.startLoad();
    case 'mediaError':
      return mediaSourceTech.recoverMediaError();
    default:
      unrecoverableErrorData.value = data;
  }
};

const onError = (event: MediaSourceTechEvent<MediaSourceTechEventError>) => {
  if (event.tech === 'html5') {
    return handleNativeMediaError(event.originalEvent as MediaError);
  }

  if (event.tech === 'hls') {
    return handleHlsError(event.originalEvent as ErrorData);
  }

  throw new UnexpectedComponentStateError('MediaSourceFatalErrorTrap - onError');
};

watch(
  unrecoverableErrorData,
  (error) => {
    if (!error) {
      return;
    }

    if (error instanceof Error) {
      throw error;
    }

    throw new HlsRuntimeError(error);
  },
  { immediate: true },
);

useEffect(() => {
  const mediaSourceTech = getMediaSourceTech();

  mediaSourceTech.on('error', onError);

  return () => {
    mediaSourceTech.off('error', onError);
  };
});
</script>
