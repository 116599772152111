import type { DSMLOptions } from '@package/dsml-js/src/core/dsml';
import type { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import { AnalyticParams, GAValue } from '@package/sdk/src/analytics/composables/kmza';
import { isDefined } from '@package/sdk/src/core';

import type { DeviceService } from '../device/device-service';
import type { EnvironmentService } from '../environment/environment-service';
import { DsmlValue } from './analytic-types';

declare global {
  interface Window {
    $dsml: any;
  }
}

function toDSMLFormat(values: GAValue[]): DsmlValue[] {
  return values.map(({ label, value }) => ({ property: label, value }));
}

export default class DsmlService {
  private isInitialized = false;

  constructor(
    private readonly $device: DeviceService,
    private readonly $environment: EnvironmentService,
  ) {}

  public setFeatureFlags(dict: Record<string, unknown>) {
    if (!window.$dsml) {
      console.warn('Expect window.$dsml to be defined');
      return;
    }

    window.$dsml.setFeatureFlags(dict);
  }

  public async init() {
    const partnerId = this.$environment.getVariable<string>('dsmlPartnerId');
    const password = this.$environment.getVariable<string>('dsmlPassword');

    if (!partnerId || !password) {
      console.warn('Expect partnerId or password to be defined');
      return;
    }

    if (!window.$dsml) {
      console.warn('Expect window.$dsml to be defined');
      return;
    }

    const appVersion = this.$environment.getVariable<() => string>('appVersion')();

    const options: DSMLOptions = {
      partnerId,
      password,
      clientType: 'smart_tv',
      storageType: 'localstorage',
      variation: 'ru',
      appVersion,
      eventSenderMode: 'default',
    };

    const dsmlEnv = this.$environment.getVariable('isRelease') ? 'production' : 'development';

    window.$dsml.setEnvironment(dsmlEnv);
    await window.$dsml.init(options);

    this.isInitialized = true;
    this.setUser({ visitorId: this.$device.getVisitorId() });
  }

  public setUser(params: any): void {
    if (!window.$dsml) {
      return console.warn('Expect window.$dsml to be defined');
    }

    window.$dsml.setUser(params);
  }

  public sendEvent({ name, values = [], options = {}, page }: AnalyticParams): void {
    if (!this.isInitialized) {
      return console.warn('Expect DsmlService to be initialized');
    }

    if (!window.$dsml) {
      return console.warn('Expect window.$dsml to be defined');
    }

    const analyticEvent: any = {
      name: name as AnalyticEventNameNew,
      page,
      values: isDefined(values) ? toDSMLFormat(values) : ([] as DsmlValue[]),
      options,
    };

    window.$dsml.sendEvent(analyticEvent);
  }
}
