import { SubscriptionPlatform } from '@package/sdk/src/api';
import { globalSettings, SessionGetters, SessionState, storeToRefs, useSessionStore } from '@package/smarttv-base/src';

export default function useSessionVariables() {
  const { _user, isPartnerSubscription, subscription, isPartnerUser } = storeToRefs<
    SessionState,
    SessionGetters,
    unknown
  >(useSessionStore());

  const isAuth = globalSettings.computed(() => Boolean(_user?.value));

  const currentUserPlatform = globalSettings.computed(() => subscription.value?.platform as SubscriptionPlatform);

  const isAppleSubscription = globalSettings.computed(() => currentUserPlatform.value === SubscriptionPlatform.Apple);
  const isGoogleSubscription = globalSettings.computed(() => currentUserPlatform.value === SubscriptionPlatform.Google);
  const isSberSubscription = globalSettings.computed(() => currentUserPlatform.value === SubscriptionPlatform.Sber);

  const isExternalSubscription = globalSettings.computed(
    () =>
      isAppleSubscription.value ||
      isGoogleSubscription.value ||
      isSberSubscription.value ||
      isPartnerSubscription.value,
  );

  return {
    isPartnerUser,
    isExternalSubscription,
    isAppleSubscription,
    isGoogleSubscription,
    isAuth,
  };
}
