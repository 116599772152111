<template>
  <div :class="$style.nextEpisode">
    <next-episode-preview-element
      v-if="isWeb && episode.preview"
      :episode-title="getContentTitle(episode.title)"
      :is-button-animated="false"
      :on-request-episode="doRequestEpisode"
      :preview-src="episode.preview"
    />

    <smart-tv-next-episode-preview-element
      v-if="isSmartTV"
      :season="season"
      :episode="episode"
      :is-episode-selected="false"
      :is-episode-unavailable="true"
      :durationLeftInMinutes="durationLeftInMinutes"
      @active="$emit('active')"
    />
  </div>
</template>

<script setup lang="ts">
import type { Episode, Season } from '@package/sdk/src/api';
import { roundNumber } from '@PLAYER/player/base/number';
import SmartTvNextEpisodePreviewElement from '@PLAYER/player/components/media-information//SmartTvNextEpisodePreviewElement.vue';
import NextEpisodePreviewElement from '@PLAYER/player/components/media-information/NextEpisodePreviewElement.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { usePlayerTexts } from '@PLAYER/player/modules/hooks/use-player-texts';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps<{
  episode: Episode;
  season: Season;
  onRequestEpisode: VoidFunction;
}>();

const { getContentTitle } = usePlayerTexts();
const { timelineBarHandleEl } = storeToRefs(useVideoControlsStore());
const { isWeb, isSmartTV } = usePlatform();

const durationLeftInMinutes = computed(() => roundNumber(props.episode.duration / 60));

const doRequestEpisode = () => {
  Reflect.apply(props.onRequestEpisode, undefined, []);

  if (timelineBarHandleEl.value) {
    window.$setActiveNavigationItem(timelineBarHandleEl.value);
  }
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

.nextEpisode {
  position: absolute;
  bottom: adjust.adjustPx(168px);
  left: adjust.adjustPx(-110px);
  display: grid;
  width: 100%;
  background-color: transparent;
  grid-template-columns: 1fr;
  gap: var(--g-spacing-8);
}
</style>
