import { ApiMoment, MomentMapper } from '@package/sdk/src/api';
import { Compilation } from '@package/sdk/src/api/compilations/compilation';
import { CompilationMapper } from '@package/sdk/src/api/compilations/compilation-mapper';
import { ApiCompilation } from '@package/sdk/src/api/compilations/compilation-types';
import { ApiCountry } from '@package/sdk/src/api/content/content-types/country';
import { ApiGenre } from '@package/sdk/src/api/content/content-types/genre';
import { ApiMedia } from '@package/sdk/src/api/content/content-types/media';
import { ApiMovie } from '@package/sdk/src/api/content/content-types/movie';
import { ApiSerial } from '@package/sdk/src/api/content/content-types/serial';
import { ApiStreamManifest } from '@package/sdk/src/api/content/content-types/stream-manifest-types';
import { CountryMapper } from '@package/sdk/src/api/content/country';
import { GenreMapper } from '@package/sdk/src/api/content/genre';
import { MediaMapper } from '@package/sdk/src/api/content/media';
import { SerialMapper } from '@package/sdk/src/api/content/serial';
import { StreamManifestMapper } from '@package/sdk/src/api/content/stream-manifest';
import { MediaContentType } from '@package/sdk/src/api/content/types/content-type';
import { Country } from '@package/sdk/src/api/content/types/country';
import { Genre } from '@package/sdk/src/api/content/types/genre';
import { Media } from '@package/sdk/src/api/content/types/media';
import { Moment } from '@package/sdk/src/api/content/types/moment';
import { Movie } from '@package/sdk/src/api/content/types/movie';
import { Serial } from '@package/sdk/src/api/content/types/serial';
import { StreamManifest } from '@package/sdk/src/api/content/types/stream-manifest';
import { Year } from '@package/sdk/src/api/content/types/year';
import { MovieMapper } from '@package/sdk/src/api/movie/movie';
import { RecommendationsRandomMapper } from '@package/sdk/src/api/recommendations/random-content-recommendation-mapper';
import {
  ApiContentRecommendationsRandom,
  ContentRecommendationsRandom,
} from '@package/sdk/src/api/recommendations/recommendation-types';
import { LikeState } from '@package/sdk/src/api/user-collection/like-state';
import { WatchingItem } from '@package/sdk/src/api/watching-items/types/watching-item';
import { WatchingItemMapper } from '@package/sdk/src/api/watching-items/watching-item';
import { ApiWatchingItem } from '@package/sdk/src/api/watching-items/watching-item-types';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';

import { MOMENT_DISLIKED_STORAGE_KEY, MOMENT_LIKED_STORAGE_KEY, MOMENTS_SAVED_STORAGE_KEY } from '../collection/common';
import type { DeviceService } from '../device/device-service';
import { Endpoints } from '../endpoints';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type { IStorageService } from '../storage/storage-service';
import type { ContentFetchWatchingParams, ContentParams, ContentUpdateWatchParams } from './catalog-types';

const createWatchingItemContentURL = (data: { profileId: string; contentId: string }) => {
  const { profileId, contentId } = data;

  const url = Endpoints.ProfilesWatchingItemContent.replace(':content_id', contentId).replace(':profile_id', profileId);

  return url;
};

export class CatalogService {
  constructor(
    private readonly requestService: RequestService,
    private readonly storageService: IStorageService,
    private readonly deviceService: DeviceService,
  ) {}

  public get likedItems() {
    return (this.storageService.getItem<string[]>(MOMENT_LIKED_STORAGE_KEY) || []) as string[];
  }

  public get dislikedItems() {
    return (this.storageService.getItem<string[]>(MOMENT_DISLIKED_STORAGE_KEY) || []) as string[];
  }

  public get savedMomentsItems() {
    return (this.storageService.getItem(MOMENTS_SAVED_STORAGE_KEY) || []) as string[];
  }

  public abort(message = 'Cancelled by user'): void {
    this.requestService.abort(message);
  }

  private contentSerializer(params: Record<string, any>): string {
    const { years, countries, genres, ...query } = params;
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(query)) {
      if (typeof query[key] !== 'undefined') {
        searchParams.append(key, query[key]);
      }
    }

    countries.forEach((country: Country) => {
      searchParams.append('countries[]', country.code);
    });
    genres.forEach((genre: Genre) => {
      searchParams.append('genres[]', genre.id);
    });
    years.forEach((year: Year) => {
      searchParams.append('years[][from]', year.id);
      searchParams.append('years[][to]', (+year.id + 9).toString());
    });

    return searchParams.toString();
  }

  public async fetchCountries(): Promise<Country[]> {
    const { data } = await this.requestService.request<ApiCountry[]>({
      method: HTTPRequestMethod.Get,
      url: Endpoints.CatalogFilterCountries,
    });

    return CountryMapper.mapMany(data);
  }

  public async fetchGenres(): Promise<Genre[]> {
    const { data } = await this.requestService.request<ApiGenre[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.CatalogFilterGenres,
      },
      { canAbort: false },
    );

    return GenreMapper.mapMany(data);
  }

  public fetchPeriods(): Year[] {
    const ALL_PERIODS = ['2020', '2010', '2000', '1990', '1980', '1970', '1960', '1950', '1940'];

    return ALL_PERIODS.map((p) => ({
      id: p,
      title: p + 'е',
      from: Number(p),
      to: Number(p),
      filterType: 'year',
      printed: p,
      slug: p,
    }));
  }

  public async fetchColdRecommendations(): Promise<Media[]> {
    const visitorId = this.deviceService.getVisitorId();

    const { data } = await this.requestService.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentColdRecommendations,
        params: {
          visitor_id: visitorId,
        },
      },
      { transformResult: true, canAbort: false },
    );

    return MediaMapper.mapMany(data);
  }

  public async fetchPersonalRecommendations(): Promise<Media[]> {
    const { data } = await this.requestService.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentPersonalRecommendations,
      },
      { withToken: true, transformResult: true },
    );

    return MediaMapper.mapMany(data);
  }

  public async fetchMoments(contentId: string): Promise<Moment[]> {
    const { data } = await this.requestService.request<ApiMoment[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentMoments.replace(':id', contentId),
      },
      { withToken: true, transformResult: true, skipTokenValidation: true },
    );

    const saved = {
      likedItems: this.likedItems,
      dislikedItems: this.dislikedItems,
      savedMomentsItems: this.savedMomentsItems,
      removedMomentsItems: [],
    };

    const moments = MomentMapper.mapMany(data);

    return moments.map((moment) => {
      let likeState = moment.likeState;

      if (saved.likedItems?.find((id) => id === moment.id)) {
        likeState = LikeState.Like;
      }

      if (saved.dislikedItems?.find((id) => id === moment.id)) {
        likeState = LikeState.Dislike;
      }

      let saveState = moment.inUserCollection;

      if (saved.savedMomentsItems?.find((id) => id === moment.id)) {
        saveState = true;
      }

      if (saved.removedMomentsItems?.find((id) => id === moment.id)) {
        saveState = false;
      }

      return { ...moment, likeState, inUserCollection: saveState };
    });
  }

  public async fetchContinueWatchItemsV2(): Promise<Media[]> {
    try {
      const { data } = await this.requestService.request<ApiMedia[]>(
        {
          method: HTTPRequestMethod.Get,
          url: Endpoints.ContinueWatchingItems,
        },
        { withToken: true, transformResult: true },
      );

      return MediaMapper.mapMany(data);
    } catch (error) {
      // expected for unauthorized
      return [];
    }
  }

  public async getWatchingItemByContentId(params: ContentFetchWatchingParams): Promise<WatchingItem | undefined> {
    const { profileId, contentId } = params;

    if (!profileId) {
      throw new UnexpectedComponentStateError('profileId');
    }

    const url = createWatchingItemContentURL({ profileId, contentId });

    const { data } = await this.requestService.request<ApiWatchingItem[]>(
      {
        method: HTTPRequestMethod.Get,
        url,
      },
      {
        withToken: true,
      },
    );

    if (data[0]) {
      return WatchingItemMapper.map(data[0]);
    }

    return undefined;
  }

  public async updateWatchItem(params: ContentUpdateWatchParams): Promise<void> {
    const { offset, duration, sign, id, profileId } = params;

    const url = createWatchingItemContentURL({ profileId, contentId: id });

    await this.requestService.request(
      {
        method: HTTPRequestMethod.Put,
        url,
        headers: {
          HMAC: sign,
        },
        data: {
          offset: Math.floor(offset),
          duration,
        },
      },
      { withToken: true, transformResult: true },
    );
  }

  public async hideWatchItem(profileId: string, contentId: string): Promise<void> {
    const url = createWatchingItemContentURL({ profileId, contentId });

    await this.requestService.request(
      { method: HTTPRequestMethod.Delete, url },
      { withToken: true, transformResult: true },
    );
  }

  public async fetchSerialManifest(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = {},
  ): Promise<StreamManifest> {
    const { data } = await this.requestService.request<ApiStreamManifest>(
      {
        method: HTTPRequestMethod.Get,
        headers,
        params: query,
        url: Endpoints.ContentSerialManifest.replace(':id', id),
      },
      { withToken: true, transformResult: true },
    );

    return StreamManifestMapper.map(data);
  }

  public async fetchMovieManifest(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = {},
  ): Promise<StreamManifest> {
    const { data } = await this.requestService.request<ApiStreamManifest>(
      {
        method: HTTPRequestMethod.Get,
        headers,
        params: query,
        url: Endpoints.ContentMovieManifest.replace(':id', id),
      },
      { withToken: true, transformResult: true },
    );

    return StreamManifestMapper.map(data);
  }

  public async fetchMovie(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = {},
  ): Promise<Movie> {
    const { data } = await this.requestService.request<ApiMovie>(
      {
        method: HTTPRequestMethod.Get,
        headers,
        params: query,
        url: Endpoints.ContentMoviesId.replace(':id', id),
      },
      { transformResult: true, withToken: true, skipTokenValidation: true },
    );

    return MovieMapper.map(data);
  }

  public async fetchMovies(params: ContentParams, headers: Record<string, any> = {}): Promise<Movie[]> {
    const { size, page, periods = [], countries = [], genres = [] } = params;

    const { data } = await this.requestService.request<ApiMovie[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentMovies,
        headers,
        params: {
          page,
          per_page: size,
          years: periods,
          countries,
          genres,
          with_seasons: false,
          with_locked: false,
        },
        paramsSerializer: {
          serialize: this.contentSerializer,
        },
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    return MovieMapper.mapMany(data);
  }

  public async fetchSerial(
    id: string,
    headers: Record<string, any> = {},
    query: Record<string, any> = {},
  ): Promise<Serial> {
    const { data } = await this.requestService.request<ApiSerial>(
      {
        method: HTTPRequestMethod.Get,
        params: query,
        url: Endpoints.ContentSerialsId.replace(':id', id),
        headers,
      },
      { transformResult: true, withToken: true, skipTokenValidation: true },
    );

    return SerialMapper.map(data);
  }

  public async fetchSerials(params: ContentParams): Promise<Serial[]> {
    const { size, page, periods = [], countries = [], genres = [] } = params;

    const { data } = await this.requestService.request<ApiSerial[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentSerials,
        params: {
          page,
          per_page: size,
          years: periods,
          countries,
          genres,
          with_seasons: false,
          with_locked: false,
        },
        paramsSerializer: {
          serialize: this.contentSerializer,
        },
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    return SerialMapper.mapMany(data);
  }

  public async fetchAll(params: ContentParams): Promise<(Movie | Serial)[]> {
    const { size, page, periods = [], countries = [], genres = [] } = params;

    const { data } = await this.requestService.request<(ApiSerial | ApiMovie)[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.Content,
        params: {
          page,
          per_page: size,
          years: periods,
          countries,
          genres,
          with_seasons: false,
          with_locked: false,
        },
        paramsSerializer: {
          serialize: this.contentSerializer,
        },
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    return data.map((item) => {
      if (item.content_type === 'movie') {
        return MovieMapper.map(item as ApiMovie);
      } else {
        return SerialMapper.map(item as ApiSerial);
      }
    });
  }

  public async fetchSimilar(id: string): Promise<Media[]> {
    const { data } = await this.requestService.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentSimilar.replace(':id', id),
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    return MediaMapper.mapMany(data);
  }

  public async fetchCollections(id: string, options?: { withContent: boolean }): Promise<Compilation[]> {
    const { data } = await this.requestService.request<ApiCompilation[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentCollections.replace(':id', id),
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    if (options?.withContent) {
      await Promise.all(
        data.map(async (collection) => {
          const { data: items } = await this.requestService.request<ApiCompilation>(
            {
              method: HTTPRequestMethod.Get,
              url: Endpoints.ContentCollectionsInfo.replace(':id', collection.id),
            },
            { withToken: true, skipTokenValidation: true, transformResult: true },
          );
          collection.contents = items.contents;
        }),
      ).catch(console.error);
    }

    return CompilationMapper.mapMany(data);
  }

  public async fetchCollectionsInfo(id: string): Promise<Compilation> {
    const { data } = await this.requestService.request<ApiCompilation>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentCollectionsInfo.replace(':id', id),
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    return CompilationMapper.map(data);
  }

  public async fetchContentRecommendationsRandom(
    contentType: MediaContentType = MediaContentType.Movie,
  ): Promise<ContentRecommendationsRandom> {
    const { data } = await this.requestService.request<ApiContentRecommendationsRandom>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.ContentRecommendationsRandom,
        params: {
          content_type: contentType,
        },
      },
      { withToken: true, skipTokenValidation: true, transformResult: true },
    );

    return RecommendationsRandomMapper.map(data);
  }
}
