import { Disposable } from '@package/sdk/src/core';

export abstract class AbstractManufacturerService extends Disposable {
  public abstract getDeviceInfo(): unknown;

  public abstract getManufacturerName(): string;

  public abstract getHumanReadableDeviceName(): string;

  public abstract init(): Promise<void>;

  public abstract isWeakDevice(): boolean;

  public abstract exit(): void;

  public abstract setVolume(volume: number | 'up' | 'down'): void;

  public abstract getVolume(): number;

  public abstract getAvailableFeatures(): string[];
}
