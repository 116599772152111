import { Disposable, isNumber, toDisposable, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { TimeSeconds } from '@PLAYER/player/base/number';
import logger from '@PLAYER/player/modules/logger/default-logger';
import {
  MediaSourceTechEvent,
  MediaSourceTechEventError,
  MediaSourceTechEventFragmentChanged,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import AbstractMediaTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';

class Html5VideoElementData extends Disposable {
  public bufferInfo: MediaSourceTechBufferInfo = { length: 0, start: 0 };

  constructor() {
    super();
  }

  public updateBuffer(buffered: TimeRanges, currentTime: TimeSeconds) {
    let range = 0;
    const time = currentTime;
    const bf = buffered;

    while (!(bf.start(range) <= time && time <= bf.end(range))) {
      range += 1;
    }

    const bufferStart = bf.start(range);
    const bufferEnd = bf.end(range);

    const start = bufferStart;
    const length = bufferEnd - bufferStart;

    this.bufferInfo = { length, start };
  }
}

export default class Html5MediaTech extends AbstractMediaTech<HTMLVideoElement> {
  private readonly tech = new Html5VideoElementData();

  constructor() {
    super();
  }

  public get bandwidth(): number {
    return 0;
  }

  public get videoCodec(): string {
    return '';
  }

  public get audioCodec(): string {
    return '';
  }

  public get currentQualityLevelHeight(): number {
    return 0;
  }

  public get latency(): number {
    return 0;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    return this.tech.bufferInfo;
  }

  private callWithSafeVideoContext = (callback: (videoEl: HTMLVideoElement) => void) => {
    if (!this.videoEl) {
      throw new UnexpectedComponentStateError('videoEl');
    }

    return Reflect.apply(callback, undefined, [this.videoEl]);
  };

  protected registerListeners(): void {
    if (!this.videoEl) {
      throw new UnexpectedComponentStateError('videoEl');
    }

    const onError = () =>
      this.callWithSafeVideoContext((videoEl) => {
        const mediaError = videoEl.error;

        const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'html5',
          originalEvent: mediaError,
          data: { errorType: '', fatal: false },
        });

        this.emitter.emit('error', mediaEvent);
      });

    const onTimeupdate = () =>
      this.callWithSafeVideoContext((videoEl) => {
        try {
          this.tech.updateBuffer(videoEl.buffered, videoEl.currentTime);

          // Иногда в буфере может быть пусто, поэтому надо сделать перепроверку на всякий
          if (videoEl.buffered.length > 0) {
            const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventFragmentChanged>({
              tech: 'html5',
              originalEvent: undefined,
              data: {
                startFragmentTime: videoEl.seekable.start(0),
                currentTime: videoEl.currentTime,
                levelTotalDuration: videoEl.duration,
              },
            });

            this.emitter.emit('fragment-changed', mediaEvent);
          }
        } catch (error) {
          logger.error(error);
        }
      });

    this.videoEl.addEventListener('error', onError);
    this.videoEl.addEventListener('timeupdate', onTimeupdate);

    this.disposableStore.add(toDisposable(() => this.videoEl?.removeEventListener('timeupdate', onTimeupdate)));
    this.disposableStore.add(toDisposable(() => this.videoEl?.removeEventListener('error', onError)));
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public loadSource(options: MediaSourceLoadOptions) {
    const { src, offset } = options;

    return this.callWithSafeVideoContext((videoEl) => {
      videoEl.src = src;

      if (isNumber(offset)) {
        videoEl.currentTime = offset;
      }
    });
  }

  public attachMedia(element: HTMLVideoElement) {
    super.attachMedia(element);

    this.registerListeners();
  }

  public detachMedia() {
    super.detachMedia();
  }

  public setNextLevel(_: number) {
    logger.warn('Html5MediaTech - setNextLevel. Method not available for HTML 5 players');
  }

  public getNextLevel() {
    logger.warn('Html5MediaTech - getNextLevel. Method not available for HTML 5 players');

    return 0;
  }

  public recoverMediaError(): void {
    this.callWithSafeVideoContext((videoEl) => {
      // По каким-то причинам, проигрывание стопнулось, экран зафризился
      const src = videoEl.src;
      const currentTime = videoEl.currentTime;

      // А вдруг такое, а что
      if (!src) {
        return logger.error('Html5MediaTech - recoverMediaError', 'Try to recover, but not src found');
      }

      // паузим видео, на всякий СЛУЧАЙ. Мы не можем быть в чем-то уверенны в этой жизни
      videoEl.pause();
      // load перезапускает видео с начала, заново инициализирует видео
      videoEl.load();
      // возвращем к тому времени, что было раньше
      videoEl.currentTime = currentTime;
    });
  }

  public startLoad(_: number): void {
    return this.callWithSafeVideoContext((videoEl) => videoEl.load());
  }

  public stopLoad(): void {
    logger.warn('Html5MediaTech - stopLoad. // Method not available for HTML 5 players');
  }

  public dispose() {
    this.tech.dispose();

    super.dispose();
  }
}
