import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import MediaSourceTech from '@PLAYER/player/tech/media-source-tech';
import { inject } from 'vue';

const injectKey = 'getMediaSourceTech';

export default function useSafeGetMediaSourceTech() {
  const { getDefault } = useDefaultProvide();

  const getMediaSourceTech = inject<() => MediaSourceTech>(injectKey, getDefault(injectKey));

  if (!getMediaSourceTech) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return getMediaSourceTech;
}
