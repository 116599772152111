import { ApiMedia } from '@package/sdk/src/api/content/content-types/media';
import { MediaMapper } from '@package/sdk/src/api/content/media';
import { MediaContentType } from '@package/sdk/src/api/content/types/content-type';
import { Media } from '@package/sdk/src/api/content/types/media';
import { Moment } from '@package/sdk/src/api/content/types/moment';
import { MomentMapper } from '@package/sdk/src/api/moments/moment';
import { ApiMoment } from '@package/sdk/src/api/moments/moment-types/moment';
import { ApiCollectionItem } from '@package/sdk/src/api/user-collection/collection';
import { CollectionContentType } from '@package/sdk/src/api/user-collection/collection-content-type';
import { CollectionItem } from '@package/sdk/src/api/user-collection/collection-item';
import { CollectionItemMapper } from '@package/sdk/src/api/users/collection-item';

import { Endpoints } from '../endpoints';
import logger from '../logger/logger';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type { IStorageService } from '../storage/storage-service';

const FILM_SAVED_STORAGE_KEY = 'collection:saved';
const FILM_REMOVED_STORAGE_KEY = 'collection:removed';
const SERIAL_SAVED_STORAGE_KEY = 'serial:saved';
const SERIAL_REMOVED_STORAGE_KEY = 'serial:removed';
const MOMENTS_SAVED_STORAGE_KEY = 'moments:saved';
const MOMENTS_REMOVED_STORAGE_KEY = 'moments:removed';
const NUMBER_OF_ITEMS_TO_SAVE_LIMIT = 5;

export interface FetchCollectionOptions {
  page: number;
  size?: number;
}

export class CollectionService {
  constructor(
    private readonly $http: RequestService,
    private readonly $storage: IStorageService,
  ) {}

  get savedFilmsItems() {
    return (this.$storage.getItem(FILM_SAVED_STORAGE_KEY) || []) as string[];
  }

  set savedFilmsItems(id: string[]) {
    this.$storage.setItem(FILM_SAVED_STORAGE_KEY, id);
  }

  get removedFilmsItems() {
    return (this.$storage.getItem(FILM_REMOVED_STORAGE_KEY) || []) as string[];
  }

  set removedFilmsItems(id: string[]) {
    this.$storage.setItem(FILM_REMOVED_STORAGE_KEY, id);
  }

  get savedSerialsItems() {
    return (this.$storage.getItem(SERIAL_SAVED_STORAGE_KEY) || []) as string[];
  }

  set savedSerialsItems(id: string[]) {
    this.$storage.setItem(SERIAL_SAVED_STORAGE_KEY, id);
  }

  get removedSerialsItems() {
    return (this.$storage.getItem(SERIAL_REMOVED_STORAGE_KEY) || []) as string[];
  }

  set removedSerialsItems(id: string[]) {
    this.$storage.setItem(SERIAL_REMOVED_STORAGE_KEY, id);
  }

  get savedMomentsItems() {
    return (this.$storage.getItem(MOMENTS_SAVED_STORAGE_KEY) || []) as string[];
  }

  set savedMomentsItems(id: string[]) {
    this.$storage.setItem(MOMENTS_SAVED_STORAGE_KEY, id);
  }

  get removedMomentsItems() {
    return (this.$storage.getItem(MOMENTS_REMOVED_STORAGE_KEY) || []) as string[];
  }

  set removedMomentsItems(id: string[]) {
    this.$storage.setItem(MOMENTS_REMOVED_STORAGE_KEY, id);
  }

  public abort(message = 'Cancelled by user') {
    logger.info('CollectionService: request aborted', message);
    this.$http.abort(message);
  }

  public async updateSavedItems() {
    try {
      const [
        savedFilmsItems,
        savedSerialsItems,
        savedMomentsItems,
        removedFilmsItems,
        removedSerialsItems,
        removedMomentsItems,
      ] = await Promise.all([
        this.saveItems(this.savedFilmsItems),
        this.saveItems(this.savedSerialsItems, MediaContentType.Serial),
        this.saveItems(this.savedMomentsItems, 'moment'),
        this.removeItems(this.removedFilmsItems),
        this.removeItems(this.removedSerialsItems, MediaContentType.Serial),
        this.removeItems(this.removedMomentsItems, 'moment'),
      ]);

      if (savedFilmsItems) {
        this.savedFilmsItems = [];
      }

      if (savedSerialsItems) {
        this.savedSerialsItems = [];
      }

      if (savedMomentsItems) {
        this.savedMomentsItems = [];
      }

      if (removedFilmsItems) {
        this.removedFilmsItems = [];
      }

      if (removedSerialsItems) {
        this.removedSerialsItems = [];
      }

      if (removedMomentsItems) {
        this.removedMomentsItems = [];
      }
    } catch (e: any) {
      logger.info(e);
    }
  }

  public async saveItems(
    ids: string[],
    type: MediaContentType | 'moment' = MediaContentType.Movie,
  ): Promise<CollectionItem[] | undefined> {
    try {
      if (!ids.length) {
        return;
      }

      const { data } = await this.$http.request<ApiCollectionItem[]>(
        {
          method: HTTPRequestMethod.Post,
          url: Endpoints.CollectionItems,
          data: {
            content_ids: ids,
          },
        },
        { withToken: true, transformResult: true },
      );

      return CollectionItemMapper.mapMany(data);
    } catch {
      const momentsItems = Array.from(new Set([...this.savedMomentsItems, ...(type === 'moment' ? ids : [])]));
      const serialsItems = Array.from(new Set([...this.savedSerialsItems, ...(type === 'serial' ? ids : [])]));
      const filmsItems = Array.from(new Set([...this.savedFilmsItems, ...(type === 'movie' ? ids : [])]));

      if (filmsItems.length + serialsItems.length + momentsItems.length > NUMBER_OF_ITEMS_TO_SAVE_LIMIT) {
        throw new Error('pages.collection.limitReached');
      }

      if (type === 'moment') {
        this.removedMomentsItems = this.removedMomentsItems.filter((id) => !ids.includes(id));

        this.savedMomentsItems = momentsItems;
        return;
      }

      if (type === 'serial') {
        this.removedSerialsItems = this.removedSerialsItems.filter((id) => !ids.includes(id));

        this.savedSerialsItems = serialsItems;
        return;
      }

      this.removedFilmsItems = this.removedFilmsItems.filter((id) => !ids.includes(id));

      this.savedFilmsItems = filmsItems;
    }
  }

  public async removeItems(
    ids: string[],
    type: MediaContentType | 'moment' = MediaContentType.Movie,
  ): Promise<boolean[] | undefined> {
    try {
      if (!ids.length) {
        return;
      }

      const collections = await Promise.all(
        ids.map((id) =>
          this.$http.request<boolean>(
            {
              method: HTTPRequestMethod.Delete,
              url: Endpoints.CollectionItems,
              params: {
                content_id: id,
              },
            },
            { withToken: true, transformResult: true },
          ),
        ),
      );

      return collections.filter(({ data }) => data).map(({ data }) => data);
    } catch {
      if (type === 'moment') {
        const items = Array.from(new Set([...this.removedMomentsItems, ...ids]));

        this.savedMomentsItems = this.savedMomentsItems.filter((id) => !ids.includes(id));

        this.removedMomentsItems = items;
        return;
      }

      if (type === 'serial') {
        const items = Array.from(new Set([...this.removedSerialsItems, ...ids]));

        this.savedSerialsItems = this.savedSerialsItems.filter((id) => !ids.includes(id));

        this.removedSerialsItems = items;
        return;
      }

      this.savedFilmsItems = this.savedFilmsItems.filter((id) => !ids.includes(id));

      this.removedFilmsItems = Array.from(new Set([...this.removedFilmsItems, ...ids]));
    }
  }

  public async fetchCollectionMoments(options: FetchCollectionOptions): Promise<Moment[]> {
    const { data } = await this.$http.request<ApiCollectionItem[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.CollectionItems,
        params: {
          content_type: CollectionContentType.ContentMoment,
          page: options.page,
          per_page: options.size,
        },
      },
      { withToken: true, transformResult: true },
    );

    return MomentMapper.mapMany(data.map((v) => v.content as ApiMoment));
  }

  public async fetchCollectionContent(options: FetchCollectionOptions): Promise<Media[]> {
    const { data } = await this.$http.request<ApiCollectionItem[]>(
      {
        method: HTTPRequestMethod.Get,
        url: Endpoints.CollectionItems,
        params: {
          content_type: CollectionContentType.Content,
          page: options.page,
          per_page: options.size,
        },
      },
      { withToken: true, transformResult: true },
    );

    return MediaMapper.mapMany(data.map((v) => v.content as ApiMedia));
  }
}
